.addcustomer_section {
  width: 100%;
  min-height: 120vh;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 2000;
  background: rgba(0, 0, 0, 0.3);
  transition: all linear 1s 0s;
  animation: popupsAnim 0.3s linear 0s 1 normal;
}
@keyframes popupsAnim {
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
  }
}
.addcustomer_container {
  width: 700px;
  height: 750px;
  border-radius: 7px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  padding-bottom: 100px;

  background: #fff;
  overflow: scroll;
}
.userPopup_close_image {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.userPopup_error {
  width: 90%;
  text-align: center;
  color: red;
  font-size: 15px;
}
.userPopup_close_image img {
  cursor: pointer;
}
.userPopup_head {
  width: 100%;
  height: 40px;
  text-align: center;
  font-size: 17px;
  text-transform: capitalize;
}
.appointments_form {
  width: 100%;
  min-height: 70vh;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
}
.appointments_form_item {
  width: 47%;
  min-height: 50px;
  margin-top: -5px;
}
.appointments_form_item input {
  padding: 8px 7px;
  border: 1px solid #3e3636;
  margin-top: -7px;
}
.appointments_form_item input:hover {
  border: 1px solid #3e3636;
}
.appointments_form_submit_all {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.appointments_form_submit {
  width: 300px;
  height: 42px;
  background: #168c98;
  font-size: 17px;
  text-transform: uppercase;
  font-weight: 500;
  color: #fff;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.appointments_form_submit:hover {
  border: none;
  background: #0fb5ae;
}

.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
}

.password_input {
  border: 1px solid #3e3636;
  margin-top: -7px;
  box-shadow: none;
  min-height: 39.6px;
}

.password_input input {
  border: none;
  top: 3px;
  &:hover {
    border: none;
  }
}

.password_input:hover {
  border: 1px solid #3e3636;
}
