.blogChange_section{
    width: 100%;
    min-height: 80vh;
    background: lightgrey;
    padding: 30px;
}
#pageHeader_id{
    width: 90px;
}
.submitChangeHandler{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.appointments_form{
    width: 100%;
    min-height: 70vh;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
}
.appointments_form_item{
    width: 47%;
    min-height: 50px;
}
.appointments_form_item input{
    padding: 15px 7px;
    border: 1px solid #3E3636;
    margin-top: -7px;
}
.appointments_form_item input:hover{
    border: 1px solid #3E3636;
}
.appointments_form_item textarea{
    padding: 15px 7px;
    border: 1px solid #3E3636;
    margin-top: -7px;
}
.appointments_form_item textarea:hover{
    border: 1px solid #3E3636;
}
.appointments_form_submit_all{
    width: 100%;
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.appointments_form_submit{
    min-width: 120px;
    height: 40px;
    background: #168C98;
    font-size: 15.5px;
    text-transform: uppercase;
    font-weight: 500;
    color: #fff;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
}
.appointments_form_submit:hover{
    border: none;
    background: #00A099;
}
.common_edit_image_antd{
    width: 100px;
    min-height: 95px;
}
.common_edit_image_antd_container{
    width: 100px;
    min-height: 95px;
    border: 1px solid lightgrey;
    position: relative;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.common_edit_image_antd_container img{
    width: 80px;
    height: 50px;
    object-fit: fill;
    object-position: center;
}
.common_edit_image_antd_container video{
    width: 80px;
    height: 50px;
    object-fit: fill;
    object-position: center;
}
.common_edit_delt_antd{
    width: 100px;
    height: 95px;
    position: absolute;
    top: 0px;
    left: 0px;
    transition: all linear .2s 0s;
    display: none;
    align-items: center;
    justify-content: center;
}
.common_edit_delt_antd img{
    width: 25px;
    height: 25px;
    object-fit: fill;
    cursor: pointer;
}
.common_edit_image_antd_container:hover .common_edit_delt_antd{
   display: flex;
}
