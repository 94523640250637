.navbar_section::-webkit-scrollbar {
    width: 3px;
  }
  .navbar_section::-webkit-scrollbar-track {
    background: #fff; 
  }
  .navbar_section::-webkit-scrollbar-thumb {
    background: #3E3636; 
  }
  .navbar_section::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
.navbar_section{
    width: 250px;
    min-height: 100vh;
    /* height: 100vh; */
    background: #fff;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 999;
    transition: all linear .2s 0s;
    overflow: auto;
}
.navbar_section_inactive{
    width: 75px;
    min-height: 100vh;
    background: #fff;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 999;
    transition: all linear .2s 0s;
    overflow: auto;
}
.navbar_item_container{
    position: relative;
    top: 75px;
    left: 0px;
}
a{
    text-decoration: none;
    color: #3E3636;
}
.navitem_section{
    width: 100%;
    height: 40px;
    /* background: #fff; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 15px;
    cursor: pointer;
    transition: all linear .2s 0s;
    border-bottom: 1px solid lightgray;
}
.navitem_section:hover{
    background: #168C98;
    transition: all linear .2s 0s;
    color: #fff;
}
.navitem_start{
    display: flex;
    padding-left: 8px;
}
.navitem_label{
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
}
.navitem_start img{
    margin-right: 10px;
}