.auths_section {
  width: 100%;
  min-height: 100vh;
  background: #fff;
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.auths_left_section {
  width: 50%;
  height: 100vh;
  position: relative;
  top: 0px;
  left: 0px;
}
.auths_left_section img {
  width: 100%;
  height: 100vh;
  object-fit: fill;
  object-position: center;
}
.auths_section_imgs {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0px;
  left: 0px;
  object-fit: cover;
}
.auths_left_section_text {
  width: 100%;
  height: 100vh;
  position: absolute;
  bottom: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  font-weight: 300;
  text-align: center;
  color: #fff;
}
.auth_section {
  width: 50%;
  height: 100vh;
  position: relative;
  margin: 0 auto;
  padding: 20vh 0vh;
}
.admin_auth_form {
  width: 100%;
}
.admin_auth_input {
  width: 100%;
}
.admin_auth_input input {
  padding: 12px;
  border: 1px solid #ececec;
  outline: none;
}
.auth_section_start_tw {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.button_sections_ant {
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all linear 0.2s 0s;
  margin-top: 50px;
}
.button_sections_ant button {
  width: 470px;
  height: 42px;
  font-size: 15px;
  text-transform: uppercase;
  color: #fff;
  background: #168c98;
  border: none;
  outline: none;
  transition: all linear 0.2s 0s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.button_sections_ant button:hover {
  /* background: #fff; */
}

.auth_close {
  width: 552px;
  height: 20.5px;
  margin: 0 auto;
  text-align: right;
}
.auth_close img {
  cursor: pointer;
}
.auth_trelif_logo {
  /* width: 453px;
    height: 31px;
    margin: 0 auto; */
  height: 2rem;
  width: 10.5rem;
  margin: auto;
}
.auth_trelif_logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: left;
}
.auth_text_top {
  width: 453px;
  height: 55px;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
}
.auth_text_top h3 {
  font-family: Playfair Display;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #fff;
}
.auth_text_top h3 span {
  font-family: Playfair Display;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #a08862;
}
.auth_text_top p {
  font-family: Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.auth_input_field {
  width: 472px;
  min-height: 125px;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
}
.auth_checkbox {
  width: 472px;
  height: 30px;
  margin: 0 auto;
  margin-top: -10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
}
.auth_checkbox img {
  width: 20px;
  height: 20px;
  object-fit: contain;
  cursor: pointer;
  margin-top: -13px;
}
.auth_checkbox p {
  color: #3e3636;
  font-family: Helvetica;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-left: 10px;
}
.forgot_pass {
  color: #a08862;
  text-align: right;
  font-family: Lato;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  cursor: pointer;
}
.auth_checkbox p span {
  color: #b3261e;
  font-family: Helvetica;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.button_sections {
  width: 185px;
  margin: 0 auto;
  margin-top: 20px;
  text-transform: capitalize;
}
#buttonId {
  transition: all 0.2s linear 0s;
}
#buttonId:hover {
  background: #b49159;
}
#buttonId_google {
  width: 127px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 20px;
  border-radius: 24px;
  background: #f2f2f2;
  margin: 0 auto;
  margin-top: -20px;
}
#buttonId_google div {
  color: #000;
  text-align: center;
  font-family: Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}
.login_footer {
  width: 235px;
  height: 35px;
  margin: 0 auto;
  color: #fff;
  text-align: center;
  font-family: Helvetica;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  margin-top: 11px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.login_footer span {
  color: #fff;
  text-transform: capitalize;
  cursor: pointer;
}
