.blog_section{
    width: 100%;
    min-height: 80vh;
    background: lightgrey;
    padding: 30px;
    overflow: auto;
}
.delete_edit_hand,
.delete_edit_hand_1{
    cursor: pointer;
    min-width: 200px;
    height: 35px;
    padding: 0px 10px;
    border: 1px solid red;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 5px;
    transition: all linear .2s 0s;
}
.delete_edit_hand div{
    text-transform: uppercase;
    font-size: 13px;
}
.delete_edit_hand img{
    height: 20px;
}
.delete_edit_hand_1 img{
    height: 20px;
    margin-right: 8px;
}
.delete_edit_hand_1{
    display: flex;
    align-items: center;
    border: 1px solid #168C98;
    text-transform: uppercase;
    font-size: 13px;
}
.delete_edit_hand:hover,
.delete_edit_hand_1:hover{
    background: #168C98;
    color: #fff;
    border: 1px solid #168C98;
}
.common_style_image{
    width: 55px;
    height: 45px;
    border-radius: 7px;
}
.common_style_image img{
    width: 55px;
    height: 45px;
    border-radius: 7px;
    object-fit: fill;
    object-position: center;
}
.orders_section_product_pp{
    display: flex;
    align-items: center;
    justify-content: center;
}
.orders_section_product_pp img{
    cursor: pointer;
}