.edit_variant_section{
    width: 100%;
    min-height: 100vh;
    transition: all linear 1s 0s;
}
.variant_edit_container{
    width: 100%;
    height: 80vh;
    margin-top: 20px;
    overflow: auto;
    padding: 20px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}