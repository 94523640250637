.variant_data_show_section{
    width: 100%;
    min-height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 1010;
    background: lightgrey;
    padding: 30px;
    transition: all linear 1s 0s;
    animation: popupsAnim .3s linear 0s 1 normal;
}
@keyframes popupsAnim {
    0%{
        transform: scaleY(0);
    }
    100%{
        transform: scaleY(1);
    }
}

.variant_data_show_container{
    width: 100%;
    height: 80vh;
    margin-top: 20px;
    overflow: auto;
    padding: 20px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}
.designers_images_pa{
    width: 180px;
    min-height: 50px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: flex-start;
}
.designers_images_st{
    width: 55px;
    height: 45px;
    border-radius: 7px;
    margin-bottom: 2px;
    margin-right: 2px;
}
.designers_images_st img{
    width: 55px;
    height: 45px;
    border-radius: 7px;
    object-fit: fill;
    object-position: center;
}
.delete_edit_hand,
.delete_edit_hand_1{
    cursor: pointer;
    width: 100px;
    height: 35px;
    padding: 0px 7px;
    border: 1px solid red;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 5px;
    transition: all linear .2s 0s;
}
.delete_edit_hand div{
    text-transform: uppercase;
    font-size: 15px;
}
.delete_edit_hand img{
    height: 20px;
}
.delete_edit_hand_1 img{
    height: 20px;
}
.delete_edit_hand_1{
    border: 1px solid #168C98;
    text-transform: uppercase;
    font-size: 15px;
}
.delete_edit_hand:hover,
.delete_edit_hand_1:hover{
    background: #168C98;
    color: #fff;
    border: none;
}
.users_switch_btn{
    width: 95px;
    height: 33px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    margin-left: 5px;
    color: #3E3636;
    font-size: 14px;
    text-transform: uppercase;
    transition: all linear .2s 0s;
}
.users_switch_btn:hover{
    background: #168C98;
    color: #fff;
}
.edit_variant_section{
    width: 100%;
    min-height: 100vh;
    transition: all linear 1s 0s;
}
.variant_edit_container{
    width: 100%;
    min-height: 80vh;
    margin-top: 20px;
    /* overflow: auto; */
    padding: 20px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}
.appointments_form{
    width: 100%;
    min-height: 70vh;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
}
.appointments_form_item{
    width: 47%;
    min-height: 50px;
}
.appointments_form_color_picker{
    width: 47%;
    min-height: 50px;
    margin-top: 6px;
}
.appointments_form_color_picker label{
    font-size:15px ;
    font-weight: 400;
    margin-bottom: 2px;
}
.appointments_form_color_picker input{
    border: 1px solid #3E3636;
    cursor: pointer;
}
.appointments_form_color_picker_edit{
    width: 47%;
    min-height: 50px;
    margin-top: -6px;
}
.appointments_form_color_picker_edit input{
    cursor: pointer;
}
.appointments_form_item input{
    padding: 15px 7px;
    border: 1px solid #3E3636;
    margin-top: -7px;
}
.appointments_form_item input:hover{
    border: 1px solid #3E3636;
}
.appointments_form_item textarea{
    padding: 15px 7px;
    border: 1px solid #3E3636;
    margin-top: -7px;
}

.appointments_form_item textarea:hover{
    border: 1px solid #3E3636;
}
.appointments_form_submit_all{
    width: 100%;
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.appointments_form_submit{
    width: 200px;
    height: 40px;
    background: #168C98;
    font-size: 15.5px;
    text-transform: uppercase;
    font-weight: 300;
    color: #fff;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
    cursor: pointer;
}
.appointments_form_submit:hover{
    border: none;
    background: #00A099;
}
.appointments_form_add_delete{
    width: 100%;
    height: 50px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
}
.delete_edit_hand,
.delete_edit_hand_1{
    cursor: pointer;
    width: 100px;
    height: 35px;
    padding: 0px 7px;
    border: 1px solid red;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 5px;
    transition: all linear .2s 0s;
}
.delete_edit_hand div{
    text-transform: uppercase;
    font-size: 15px;
}
.delete_edit_hand img{
    height: 20px;
}
.delete_edit_hand_1{
    width: 150px;
    border: 1px solid #168C98;
    background: #168C98;
    text-transform: uppercase;
    font-size: 15px;
    color: #fff;
}
.delete_edit_hand_1:hover{
    background: #fff;
    color: #000;
}
.appointments_form_varient{
    width: 100%;
    height: 50px;
    position: absolute;
    top: -20px;
    left: 0px;
}
.appointments_form_select{
    height: 50px;
    border: 1px solid #000;
    outline: none;
    border-radius: 5px;
}
.appointments_form_select:hover{
    border:none;
}
.appointments_form_custom_select{
    width: 47%;
    height: 100px;
    margin-top: 5px;
    /* border: 1px solid red; */
}
.appointments_form_custom_select label{
    font-size: 14px;
    text-transform: capitalize;
    color: black;
    font-weight: 400;
    margin-bottom: 4px;
}
.appointments_form_custom_select label span{
    color: red;
    margin-right: 3px;
}
.appointments_form_custom_select select{
    width: 100%;
    outline: none;
    border: 1px solid #000;
    border-radius: 5px;
    padding:15.5px 10px;
    cursor: pointer;
}
.appointments_form_custom_select input{
    width: 100%;
    outline: none;
    border: 1px solid #000;
    border-radius: 5px;
    padding:15.5px 10px;
    cursor: pointer;
}
.appointments_form_custom_select select::placeholder{
    color: lightgray;

}
.common_edit_image_antd{
    width: 100%;
    min-height: 95px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: flex-start;
}
.common_edit_image_antd_container{
    width: 100px;
    min-height: 95px;
    border: 1px solid lightgrey;
    position: relative;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 3px;
    margin-bottom: 5px;
}
.common_edit_image_antd_container img{
    width: 80px;
    height: 50px;
    object-fit: fill;
    object-position: center;
}
.common_edit_delt_antd{
    width: 100px;
    height: 95px;
    position: absolute;
    top: 0px;
    left: 0px;
    transition: all linear .2s 0s;
    display: none;
    align-items: center;
    justify-content: center;
}
.common_edit_delt_antd img{
    width: 25px;
    height: 25px;
    object-fit: fill;
    cursor: pointer;
}
.common_edit_image_antd_container:hover .common_edit_delt_antd{
   display: flex;
}
#ant-upload-list-item-actions {
    display: none;
  }
  .appointments_form_item_image{
    width: 47%;
    min-height: 50px;
    display: flex;
    align-content: flex-start;
    justify-content: flex-start;
}
.images_dimensions{
    width: 100%;
    height: 40px;
    text-transform: capitalize;
    font-weight:normal;
    font-size: 15.7px;
  }
  .appointments_form_item_img{
    width: 47%;
    height: 140px;
}
.delete_edit_hand,
.delete_edit_hand_1{
    cursor: pointer;
    width: 100px;
    height: 35px;
    padding: 0px 7px;
    border: 1px solid red;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 5px;
    transition: all linear .2s 0s;
}
.delete_edit_hand div{
    text-transform: uppercase;
    font-size: 15px;
}
.delete_edit_hand img{
    height: 20px;
}
.delete_edit_hand_1 img{
    height: 20px;
}
.delete_edit_hand_1{
    border: 1px solid #168C98;
    text-transform: uppercase;
    font-size: 15px;
}
.delete_edit_hand:hover,
.delete_edit_hand_1:hover{
    background: #168C98;
    color: #fff;
    border: none;
}
.variant_edit_update_form{
    width: 100%;
    height: 40px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0px 10px;
    margin-top: 20px;
    margin-bottom: -15px;
    font-size: 17px;
    font-weight: 600;
}
.variant_edit_update_form img{
    cursor: pointer;
}