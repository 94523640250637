.product_details_popup_section{
    width: 100%;
    min-height: 100vh;
    position: fixed;
    top: 75px;
    left: 0px;
    z-index: 1010;
    background: lightgrey;
    padding: 30px;
    padding-left: 280px;
    transition: all linear 1s 0s;
    animation: popupsAnim .3s linear 0s 1 normal;
}
@keyframes popupsAnim {
    0%{
        transform: scaleY(0);
    }
    100%{
        transform: scaleY(1);
    }
}
.product_details_popup_head{
    width: 100%;
    height: 50px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    text-transform: uppercase;
    color: #3E3636;
    font-weight: 500;
    word-spacing: 5px;
    padding: 0px 3px;
}
.product_details_popup_head_second{
    cursor: pointer;
    min-width: 130px;
    height: 35px;
    padding: 0px 7px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 5px;
    border: 1px solid #3E3636;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 400;
    word-spacing: -1px;
    transition: all linear .2s 0s;
}
.product_details_popup_head_second img{
    margin-right: 5px;
}
.product_details_popup_head_second:hover{
    background: #fff;
}
.product_details_popup_data{
    width: 100%;
    min-height: 88vh;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: flex-start;
}
.product_details_popup_single_data{
    width: 300px;
    height: 400px;
    background: #fff;
}
.delete_edit_hand,
.delete_edit_hand_1{
    cursor: pointer;
    width: 225px;
    height: 35px;
    padding: 0px 7px;
    border: 1px solid red;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 5px;
    transition: all linear .2s 0s;
}
.delete_edit_hand div{
    text-transform: uppercase;
    font-size: 13px;
}
.delete_edit_hand img{
    height: 20px;
}
.delete_edit_hand_1{
    border: 1px solid #339AF0;
    text-transform: uppercase;
    font-size: 13px;
}
.delete_edit_hand:hover,
.delete_edit_hand_1:hover{
    background: #3E3636;
    color: #fff;
    border: none;
}
.designers_images_pa{
    width: 180px;
    min-height: 50px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: flex-start;
}
.designers_images_st{
    width: 55px;
    height: 45px;
    border-radius: 7px;
    margin-bottom: 2px;
    margin-right: 2px;
}
.designers_images_st img{
    width: 55px;
    height: 45px;
    border-radius: 7px;
    object-fit: fill;
    object-position: center;
}
.appointments_form{
    width: 100%;
    min-height: 70vh;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
}
.appointments_form_item{
    width: 47%;
    min-height: 50px;
}
.appointments_form_item input{
    padding: 15px 7px;
    border: 1px solid #3E3636;
    margin-top: -7px;
}
.appointments_form_item input:hover{
    border: 1px solid #3E3636;
}
.appointments_form_item textarea{
    padding: 15px 7px;
    border: 1px solid #3E3636;
    margin-top: -7px;
}
.appointments_form_item textarea:hover{
    border: 1px solid #3E3636;
}
.appointments_form_submit_all{
    width: 100%;
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.appointments_form_submit{
    min-width: 120px;
    height: 40px;
    background: #168C98;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 500;
    color: #fff;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
}
.appointments_form_submit:hover{
    border: none;
    background: #00A099;
}