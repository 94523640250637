.input_section{
    width: 100%;
}
.input_section input{
    width: 100%;
    outline: none;
    border: none;
    padding:6.7px 10px;
    border-top-left-radius:5px;
    border-bottom-left-radius: 5px;
    background: #3E3636;
    color: #A08862;
    font-size: 15px;
    font-family: Georgia, 'Times New Roman', Times, serif;
}
.input_section input::placeholder{
    color: #A08862;
    font-size: 15px;
    font-family: Georgia, 'Times New Roman', Times, serif; 
}
