.upload_header{
    color: #3E3636;
    font-family: Helvetica;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    padding-left: 5px;
    margin-bottom: 2px;
    margin-top: 5px;
    }
.label_star{
    color: #B3261E;
    font-family: Helvetica;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    }
    .label_error{
        color: #B3261E;
    font-family: Helvetica;
    font-size: 13px;
    font-style: italic;
    font-weight: 600;
    line-height: normal;
        padding-left: 8px;
    }