.blogChange_section{
    width: 100%;
    min-height: 80vh;
    background: lightgrey;
    padding: 30px;
}
#pageHeader_id{
    width: 90px;
}
.submitChangeHandler{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.appointments_form{
    width: 100%;
    min-height: 70vh;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
}
.appointments_form_item{
    width: 47%;
    min-height: 50px;
}
.appointments_form_item input{
    padding: 15px 7px;
    border: 1px solid #3E3636;
    margin-top: -7px;
}
.appointments_form_item input:hover{
    border: 1px solid #3E3636;
}
.appointments_form_item textarea{
    padding: 15px 7px;
    border: 1px solid #3E3636;
    margin-top: -7px;
}
.appointments_form_item textarea:hover{
    border: 1px solid #3E3636;
}
.appointments_form_submit_all{
    width: 100%;
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.appointments_form_submit{
    min-width: 120px;
    height: 40px;
    background: #168C98;
    font-size: 15.5px;
    text-transform: uppercase;
    font-weight: 500;
    color: #fff;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
}
.appointments_form_submit:hover{
    border: none;
    background: #00A099;
}
.appointments_form_select{
    height: 50px;
    border: 1px solid #000;
    outline: none;
    border-radius: 5px;
}
.appointments_form_select:hover{
    border:none;
}
.discounts_value_error{
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: -35px;
}
.discounts_value_error div{
    width: 46%;
    color: red;
}