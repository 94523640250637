.instore_section{
    width: 100%;
    min-height: 80vh;
    background: lightgrey;
    padding: 30px;
}
.ticket_details_accordion{
    width: 100%;
    min-height: 70vh;
    margin-top: 20px;
    /* overflow: auto; */
    padding: 20px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}
.contact_section {
    width: 100%;
    min-height: 70vh;
    margin-bottom: 20px;
  }
  .contact_container {
    width: 100%;
    min-height: 70vh;
    position: relative;
    top: 0%;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 7px;
    transition: all 0.4s ease-in-out 0s;
  }
  .contact_accordion {
    width: 100%;
    height: 8vh;
    border-radius: 8px;
    margin-bottom: 10px;
    background: #fff;
    transition: all 0.4s ease-in-out 0s;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-top: 8px;
    box-shadow: 0px 3px 6px #00000029;
  }
  .contact_accordion_active {
    width: 100%;
    min-height: 1vh;
    transition: all 0.4s ease-in-out 0s;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 5px;
    animation: accordionAnim 0.4s linear 0s 1 normal;
    background: #fff;
    margin-bottom: 10px;
    border-radius: 8px;
    padding-top: 8px;
    box-shadow: 0px 3px 6px #00000029;
  }
  @keyframes accordionAnim {
    0% {
      min-height: 0vh;
    }
    100% {
      min-height: 1vh;
    }
  }
  .contact_accordion_head {
    width: 100%;
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
    cursor: pointer;
    transition: all 0.4s ease-in-out 0s;
  }
  .contact_accordion_head h3 {
    font-size: 15px;
    font-weight: 700;
    text-transform: capitalize;
    color: #2e384d;
    font-family: Cairo;
    transition: all 0.4s ease-in-out 0s;
  }
  .contact_accordion_head img {
    width: 14px;
    height: 14px;
    transition: all 0.4s ease-in-out 0s;
  }
  .contact_accordion_desc {
    width: 100%;
    min-height: 10vh;
    display: none;
    transition: all 0.4s ease-in-out 0s;
    padding: 5px;
    animation: accordionAnims 0.2s linear 0s 1 normal;
  }
  @keyframes accordionAnims {
    0% {
      transform: translateY(0vh);
    }
    100% {
      transform: translateY(5vh);
    }
  }
  .contact_accordion_desc_active {
    width: 100%;
    min-height: 1vh;
    transition: all 0.4s ease-in-out 0s;
    padding: 20px;
    animation: accordionAnims 0.2s linear 0s 1 normal;
  }
  @keyframes accordionAnims {
    0% {
      transform: translateY(3vh);
    }
    100% {
      transform: translateY(0vh);
    }
  }
  .orderNew_label{
    width: 100%;
    margin-bottom: 13px;
    font-size: 15px;
    text-transform: capitalize;
  }
  .order_manual_status_name{
    font-size: 15px;
    font-weight: 700;
    text-transform: uppercase;
    color: #2e384d;
    font-family: Cairo;
    margin-right: 15px;
  }
  .order_manual_status{
     display: flex;
     align-items: center;
     justify-content: center;
  }
  .order_manual_status_change{
    width: 135px;
    height: 35px;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #168C98;
    text-transform: uppercase;
    font-size: 14.2px;
    transition: all .2s 0s linear;
    font-weight: 500;
    color: #2e384d;
  }
  .order_manual_status_change:hover{
    background: #168C98;
    color: #fff;
  }
  .order_id_customer_search{
    width: 100%;
    height: 50px;
  }
  .order_id_customer_search input{
      width: 300px;
      outline: none;
      border: 1px solid black;
      padding: 8px 5px;
      border-radius: 5px;
      font-size: 15px;
      font-weight: 400;
  }