.collectionPopup_section{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 1010;
    background:rgba(0, 0, 0, 0.3);
    transition: all linear 1s 0s;
    animation: popupsAnim .3s linear 0s 1 normal;
}
@keyframes popupsAnim {
    0%{
        transform: scaleY(0);
    }
    100%{
        transform: scaleY(1);
    }
}
.collectionPopup_section_container{
    width: 700px;
    min-height:580px;
    background: #fff;
    border-radius: 7px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    padding: 20px;
}
.collectionPopup_section_head{
   text-align: right;
}
.collectionPopup_section_head img{
    cursor: pointer;
}
.collectionPopup_section_label{
    width: 40%;
    margin: 0 auto;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    text-transform: capitalize;
    color: #000;
    border-bottom: 1px solid #000;
    margin-bottom: 20px;
}
.appointments_form{
    width: 100%;
    min-height: 70vh;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
}
.appointments_form_item{
    width: 47%;
    min-height: 50px;
}
.appointments_form_item input{
    padding: 15px 7px;
    border: 1px solid #3E3636;
    margin-top: -7px;
}
.appointments_form_item input:hover{
    border: 1px solid #3E3636;
}
.appointments_form_item textarea{
    padding: 15px 7px;
    border: 1px solid #3E3636;
    margin-top: -7px;
}
.appointments_form_item textarea:hover{
    border: 1px solid #3E3636;
}
.appointments_form_submit_all{
    width: 100%;
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.appointments_form_submit{
    min-width: 120px;
    height: 40px;
    background: #168C98;
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 500;
    color: #fff;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
}
.appointments_form_submit:hover{
    border: none;
    background: #00A099;
}
.common_edit_image_antd{
    width: 100px;
    min-height: 95px;
}
.common_edit_image_antd_container{
    width: 100px;
    min-height: 95px;
    border: 1px solid lightgrey;
    position: relative;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.common_edit_image_antd_container img{
    width: 80px;
    height: 50px;
    object-fit: fill;
    object-position: center;
}
.common_edit_image_antd_container video{
    width: 80px;
    height: 50px;
    object-fit: fill;
    object-position: center;
}
.common_edit_delt_antd{
    width: 100px;
    height: 95px;
    position: absolute;
    top: 0px;
    left: 0px;
    transition: all linear .2s 0s;
    display: none;
    align-items: center;
    justify-content: center;
}
.common_edit_delt_antd img{
    width: 25px;
    height: 25px;
    object-fit: fill;
    cursor: pointer;
}
.common_edit_image_antd_container:hover .common_edit_delt_antd{
   display: flex;
}
