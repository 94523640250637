.collections_section{
    width: 100%;
    min-height: 80vh;
    background: lightgrey;
    padding: 30px;
    overflow: auto;
}
/* .collections_not_found{
    width: 100%;
    height: 550px;
    color: #000;
    font-size: 22px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: center;
} */
.collections_details_container{
    width: 100%;
    min-height: 550px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
}
.collections_details{
    width: 100%;
    min-height: 550px;
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin-bottom: 10px;
    border-radius: 10px;
}
.collections_details_head{
    width: 100%;
    height: 120px;
    position: relative;
    border-bottom: 1px solid black;
}
.collections_details_head_img{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    position: absolute;
    top:10%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
}
.collections_details_head_img img{
    width: 35px;
    height: 35px;
}
.collections_details_head_label{
    width: 100%;
    position: absolute;
    bottom:5%;
    text-align: center;
    font-size: 18px;
    color: #000;
    text-transform: capitalize; 
}
.collections_details_bannerImg{
    width: 100%;
    height: fit-content;
    margin: 0 auto;
    margin-top:25px;
   position:relative;
   border-radius: 5px;
   padding:6px;
   background:#f2f2f2;
   padding-bottom: 5px;
}
.collections_details_bannerImg video{
    width: 100%;
    height: 200px;
    border-radius: 5px;
    object-fit: cover;
    object-position: center;
    -webkit-box-shadow: 0 28px 16px -26px rgba(0, 0, 0);
    -moz-box-shadow: 0 28px 16px -26px rgba(0, 0, 0);
    box-shadow: 0 28px 16px -26px rgba(0, 0, 0);
}
.collections_details_desc{
    width: 100%;
    height: 140px;
    overflow: auto;
    padding: 2%;
    margin-top: 5px;
}
.collections_details_desc p{
    width: 100%;
    height: 30px;
    text-align: center;
    font-size: 16px;
    text-transform: uppercase;
}
.collections_details_desc div{
    text-align: center;
}
.collections_details_footer{
    width: 100%;
    padding: 2% 7.5%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.delete_edit_hand_1{
    cursor: pointer;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    transition: all linear .2s 0s;
    text-transform: uppercase;
    font-size: 16px;
}
.delete_edit_hand_1 div{
    color: #fff;
    cursor: pointer;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    transition: all linear .2s 0s;
    text-transform: uppercase;
    font-size: 16px;
}
.delete_edit_hand_1 div:hover{
    background: #3E3636;
    color: #fff;
    border: none;
}

.collections_images_gallery{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: start;
    object-fit: cover;
}
.collections_image_item{
    flex: 1 1 calc(33.333% - 8px);
    max-width: calc(33.333% - 8px);
    max-width: 100px;
    max-height: 100px;
    border-radius: 5px;
    overflow: hidden;
}

.collections_image_item img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}