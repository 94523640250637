.back_button_section{
    height: 50px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    text-transform: capitalize;
    color: #2E384D;
    font-weight: 600;
    word-spacing: 5px;
    padding: 0px 3px;
}
.back_button_right{
    cursor: pointer;
    min-width: 100px;
    height: 35px;
    padding: 0px 7px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 5px;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 400;
    transition: all linear .2s 0s;
    border: 1px solid #168C98;
    color: #000;
}
.back_button_right:hover{
    background: #fff;
}