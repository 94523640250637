*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  
  .container{
    width: 100%;
    height: 75vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999999;
    background-color: transparent;
  }
  
  .boxes {
    width: 40px;
    height: 40px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
  }
  .box_1 {
    width: 50%;
    height: 50%;
    background-color: #A08862;
    position: absolute;
    animation: example 2.8s infinite linear;
    z-index: 1;
  }
  
  .box {
    width: 50%;
    height: 50%;
    animation: example1 2.8s infinite linear;
  }
  
  .b_1 {
    animation-delay: 0s;
  }
  .b_2 {
    animation-delay: 0.3s;
  }
  .b_4 {
    animation-delay: 0.7s;
  }
  .b_3 {
    animation-delay: 1s;
  }
  
  @keyframes example {
    0% {
      background-color: #A08862;
      left: 0px;
      top: 0px;
    }
    12% {
      background-color: #A08862;
      left: 50%;
      top: 0px;
    }
    24% {
      background-color: #A08862;
      left: 50%;
      top: 50%;
    }
    36% {
      background-color: #A08862;
      left: 0px;
      top: 50%;
    }
    48% {
      background-color: black;
      left: 0px;
      top: 0px;
    }
    61% {
      background-color: black;
      left: 50%;
      top: 0px;
    }
    73% {
      background-color: black;
      left: 50%;
      top: 50%;
    }
    85% {
      background-color: black;
      left: 0px;
      top: 50%;
    }
    100% {
      background-color: #A08862;
      left: 0px;
      top: 0px;
    }
  }
  
  @keyframes example1 {
    0% {
      background-color: #fff;
    }
    20% {
      background-color: #fff;
    }
    40% {
      background-color: #fff;
    }
    60% {
      background-color: #fff;
    }
    80% {
      background-color:#fff;
    }
    100% {
      background-color: #fff;
    }
  }