.home_page_header_section{
    width: 100%;
    min-height: 80vh;
    background: lightgrey;
    padding: 30px;
    overflow: auto;
}
.home_page_header_container{
    width: 100%;
    min-height: 450px;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    justify-content: space-between;
}
.home_page_header_data{
    width: 100%;
    height: 220px;
    border-radius: 7px;
    box-shadow: rgb(0, 0, 0) 0px 10px 10px -5px inset;
    padding: 15px;
    transition: all linear .2s 0s;
    margin-bottom: 20px;
    position: relative;
}
.home_page_header_data:hover{
    box-shadow: none;
    background: #fff;
}
.home_page_header_title{
    width: 100%;
    min-height: 25px;
    color: #4287f5;
    font-size: 15px;
    text-transform: uppercase;
}
.home_page_header_title span{
    color: #000;
    margin-left: 10px;
}
.home_page_header_image{
    width: 100%;
    height: 100px;
    color: #4287f5;
    font-size: 15px;
    text-transform: uppercase;
    margin-bottom: 30px;
}
.home_page_header_image span{
    width: 100%;
    margin-top: 5px;
}
.home_page_header_image img{
    width: 100%;
    height: 90px;
    object-fit: fill;
    object-position: center;
    border-radius: 2px;
}
.home_page_header_btn{
    width: 100%;
    position: absolute;
    bottom: 25px;
    left: 0px;
    padding: 0px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.delete_edit_hand,
.delete_edit_hand_1{
    cursor: pointer;
    width: 100px;
    height: 35px;
    padding: 0px 7px;
    border: 1px solid red;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 5px;
    transition: all linear .2s 0s;
}
.delete_edit_hand div{
    text-transform: uppercase;
    font-size: 15px;
}
.delete_edit_hand img{
    height: 20px;
}
.delete_edit_hand_1 img{
    height: 20px;
}
.delete_edit_hand_1{
    border: 1px solid #168C98;
    text-transform: uppercase;
    font-size: 15px;
}
.delete_edit_hand:hover,
.delete_edit_hand_1:hover{
    background: #168C98;
    color: #fff;
    border: none;
}
.common_style_image{
    width: 55px;
    height: 45px;
    border-radius: 7px;
}
.common_style_image img{
    width: 55px;
    height: 45px;
    border-radius: 7px;
    object-fit: fill;
    object-position: center;
}