.product_details_popup_section{
    width: 100%;
    min-height: 100vh;
    position: fixed;
    top: 75px;
    left: 0px;
    z-index: 1010;
    background: lightgrey;
    padding: 30px;
    padding-left: 280px;
    transition: all linear 1s 0s;
    animation: popupsAnim .3s linear 0s 1 normal;
}
@keyframes popupsAnim {
    0%{
        transform: scaleY(0);
    }
    100%{
        transform: scaleY(1);
    }
}
.product_details_popup_head{
    width: 100%;
    height: 50px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    text-transform: uppercase;
    color: #3E3636;
    font-weight: 500;
    word-spacing: 5px;
    padding: 0px 3px;
}
.product_details_popup_head_second{
    cursor: pointer;
    min-width: 100px;
    height: 35px;
    padding: 0px 7px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 5px;
    border: 1px solid #168C98;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 400;
    word-spacing: -1px;
    transition: all linear .2s 0s;
    /* color: #fff; */
}
.product_details_popup_head_second img{
    margin-right: 5px;
}
.product_details_popup_head_second:hover{
    background: #fff;
}
.product_details_popup_data{
    width: 100%;
    min-height: 88vh;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: flex-start;
}
.product_details_popup_single_data{
    width: 300px;
    height: 400px;
    background: #fff;
}
.delete_edit_hand,
.delete_edit_hand_1{
    cursor: pointer;
    width: 225px;
    height: 35px;
    padding: 0px 7px;
    border: 1px solid red;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 5px;
    transition: all linear .2s 0s;
}
.delete_edit_hand div{
    text-transform: uppercase;
    font-size: 13px;
}
.delete_edit_hand img{
    height: 20px;
}
.delete_edit_hand_1{
    border: 1px solid #339AF0;
    text-transform: uppercase;
    font-size: 13px;
}
.delete_edit_hand:hover,
.delete_edit_hand_1:hover{
    background: #3E3636;
    color: #fff;
    border: none;
}
.designers_images_pa{
    width: 180px;
    min-height: 50px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: flex-start;
}
.designers_images_st{
    width: 55px;
    height: 45px;
    border-radius: 7px;
    margin-bottom: 2px;
    margin-right: 2px;
}
.designers_images_st img{
    width: 55px;
    height: 45px;
    border-radius: 7px;
    object-fit: fill;
    object-position: center;
}

.ticket_details_accordion{
    width: 100%;
    height: 70vh;
    margin-top: 20px;
    overflow: auto;
    padding: 20px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}
.contact_section {
    width: 100%;
    min-height: 50vh;
    margin-bottom: 20px;
  }
  .contact_container {
    width: 100%;
    min-height: 50vh;
    position: relative;
    top: 0%;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 7px;
    transition: all 0.4s ease-in-out 0s;
  }
  .contact_accordion {
    width: 100%;
    height: 8vh;
    border-radius: 8px;
    margin-bottom: 10px;
    background: #fff;
    transition: all 0.4s ease-in-out 0s;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-top: 8px;
    box-shadow: 0px 3px 6px #00000029;
  }
  .contact_accordion_active {
    width: 100%;
    min-height: 1vh;
    transition: all 0.4s ease-in-out 0s;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 5px;
    animation: accordionAnim 0.4s linear 0s 1 normal;
    background: #fff;
    margin-bottom: 10px;
    border-radius: 8px;
    padding-top: 8px;
    box-shadow: 0px 3px 6px #00000029;
  }
  @keyframes accordionAnim {
    0% {
      min-height: 0vh;
    }
    100% {
      min-height: 1vh;
    }
  }
  .contact_accordion_head {
    width: 100%;
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
    cursor: pointer;
    transition: all 0.4s ease-in-out 0s;
  }
  .contact_accordion_head h3 {
    font-size: 15px;
    font-weight: 700;
    text-transform: capitalize;
    color: #2e384d;
    font-family: Cairo;
  
    transition: all 0.4s ease-in-out 0s;
  }
  .contact_accordion_head img {
    width: 14px;
    height: 14px;
    transition: all 0.4s ease-in-out 0s;
  }
  .contact_accordion_desc {
    width: 100%;
    min-height: 10vh;
    display: none;
    transition: all 0.4s ease-in-out 0s;
    padding: 5px;
    animation: accordionAnims 0.2s linear 0s 1 normal;
  }
  @keyframes accordionAnims {
    0% {
      transform: translateY(0vh);
    }
    100% {
      transform: translateY(5vh);
    }
  }
  .contact_accordion_desc_active {
    width: 100%;
    min-height: 1vh;
    transition: all 0.4s ease-in-out 0s;
    padding: 20px;
    animation: accordionAnims 0.2s linear 0s 1 normal;
  }
  @keyframes accordionAnims {
    0% {
      transform: translateY(3vh);
    }
    100% {
      transform: translateY(0vh);
    }
  }
  .product_details_popup_data_not_found{
    width: 100%;
    height: 65vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    text-transform: capitalize;
  }