.users_section{
    width: 100%;
    min-height: 80vh;
    background: lightgrey;
    padding: 30px;
}
.users_switch_btn{
    width: 95px;
    height: 33px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    margin-left: 5px;
    color: #3E3636;
    font-size: 14px;
    text-transform: uppercase;
    transition: all linear .2s 0s;
}
.users_switch_btn:hover{
    background: #168C98;
    color: #fff;
}
.orders_section_product_pp{
    display: flex;
    align-items: center;
    justify-content: center;
}
.orders_section_product_pp img{
    cursor: pointer;
}
.customer_add_new{
    cursor: pointer;
    width: 150px;
    height: 35px;
    border-radius: 5px;
    border: 1px solid #3E3636;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 10px;
    float:right;
    transition: all linear .2s 0s;
}
.customer_add_new div{
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 400;
    word-spacing: -1px;
}
.customer_add_new:hover{
    background: #fff;
}