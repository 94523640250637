.adminName {
  margin-bottom: 0 !important;
}

.adminInfo {
  margin-bottom: 2rem;
  text-transform: capitalize;
}

.tag {
  text-transform: capitalize;
}
