.top_header_slider_section{
    width: 100%;
    min-height: 80vh;
    background: lightgrey;
    padding: 30px;
    overflow: auto;
}
.top_header_slider_container{
    width: 100%;
    height: 450px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
}
.top_header_slider_data{
    width: 100%;
    height: 450px;
    border-radius: 7px;
    transition: all linear .2s 0s;
    box-shadow: rgb(0, 0, 0) 0px 10px 10px -5px inset;
    padding: 15px;
}
.top_header_slider_data:hover{
    background: #fff;
    box-shadow: none;
}
.top_header_slider_text{
    text-align: center;
    font-size: 18px;
    text-transform: uppercase;
    color: #4287f5;
    margin-bottom: 15px;
}
.top_header_slider_img{
    width: 50%;
    height: 300px;
    object-fit: contain;
}
.top_header_slider_btn{
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
}


.delete_edit_hand,
.delete_edit_hand_1{
    cursor: pointer;
    width: 100px;
    height: 35px;
    padding: 0px 7px;
    border: 1px solid red;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 5px;
    transition: all linear .2s 0s;
}
.delete_edit_hand div{
    text-transform: uppercase;
    font-size: 15px;
}
.delete_edit_hand img{
    height: 20px;
}
.delete_edit_hand_1 img{
    height: 20px;
}
.delete_edit_hand_1{
    border: 1px solid #168C98;
    text-transform: uppercase;
    font-size: 15px;
}
.delete_edit_hand:hover,
.delete_edit_hand_1:hover{
    background: #168C98;
    color: #fff;
    border: none;
}
.common_style_image{
    width: 55px;
    height: 45px;
    border-radius: 7px;
}
.common_style_image img {
    width: 100%; 
    height: 45px;
    border-radius: 7px;
    object-fit: cover;
    object-position: center;
}

.top_header_image_box {
    display: flex;
    gap: 5px;
    align-items: center;
    max-width: 100%; 
    overflow: hidden;
}