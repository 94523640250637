.dashboard_block_section{
    width: 175px;
    height: 100px;
    padding: 10px 15px;
    background: #168C981A 0% 0% no-repeat padding-box;
border: 1px solid #168C9833;
border-radius: 8px;
opacity: 1;
display: flex;
align-items: flex-start;
justify-content: space-between;
flex-direction: column;
   margin-bottom: 15px;
}
.block_section_label{
    font-size: 15px;
    font-weight: 500;
    text-transform: capitalize;
    color: #2E384D;
    margin-bottom: 10px;
}
.block_section_img_text{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}
.block_section_img{
    width: 40px;
    height: 40px;
    border-radius: 50%;;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
}
.block_section_text{
    font-size: 18px;
    font-weight: 500;
    text-transform: capitalize;
}