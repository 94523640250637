.button_section{
    display: flex;
    padding: 10px 64px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    text-transform: capitalize;
    cursor: pointer;
    background: #A08862;
    
}
.button_section div{
    color: #fff;
    font-size: 15px;
}