.VideoInput {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #ddd;
  }

  .VideoInput_input {
    display: none;
  }
  