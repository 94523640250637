.products_section{
    width: 100%;
    min-height: 80vh;
    background: lightgrey;
    padding: 30px;
    overflow: auto;
}
.categorys_collection_des{
    width: 100px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    padding: 0px 7px;
}
.delete_edit_hand,
.delete_edit_hand_1{
    cursor: pointer;
    width: 100px;
    height: 35px;
    padding: 0px 7px;
    border: 1px solid red;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 5px;
    transition: all linear .2s 0s;
}
.delete_edit_hand div{
    text-transform: uppercase;
    font-size: 15px;
}
.delete_edit_hand img{
    height: 20px;
}
.delete_edit_hand_1 img{
    height: 20px;
}
.delete_edit_hand_1{
    border: 1px solid #168C98;
    text-transform: uppercase;
    font-size: 15px;
}
.delete_edit_hand:hover,
.delete_edit_hand_1:hover{
    background: #168C98;
    color: #fff;
    border: none;
}
.common_style_image{
    width: 55px;
    height: 45px;
    border-radius: 7px;
}
.common_style_image img{
    width: 55px;
    height: 45px;
    border-radius: 7px;
    object-fit: fill;
    object-position: center;
}
.designers_images_pa{
    width: 180px;
    min-height: 50px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: flex-start;
}
.designers_images_st{
    width: 55px;
    height: 45px;
    border-radius: 7px;
    margin-bottom: 2px;
    margin-right: 2px;
}
.designers_images_st img{
    width: 55px;
    height: 45px;
    border-radius: 7px;
    object-fit: fill;
    object-position: center;
}
.products_category_tbl::-webkit-scrollbar {
    width: 2px;
  }
  .products_category_tbl::-webkit-scrollbar-track {
    background: #000; 
  }
  .products_category_tbl::-webkit-scrollbar-thumb {
    background: #000; 
  }
  .products_category_tbl::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
.products_category_tbl{
    width: 100px;
    height: 30px;
    border-radius: 5px;
    padding: 2px;
    overflow: auto;
    box-shadow: inset 0 0 10px #000;
}
.products_actions_style_change{
    cursor: pointer;
    position: relative;
    width: 50px;
}
.products_actions_style_change_btn{
    width: 225px;
    padding: 4px 5px;
    height: 80px;
    background: #fff;
    position: absolute;
    top: -25px;
    right: 0px;
    border-radius: 5px;
    box-shadow: inset 0 0 10px #000;
    animation: popupsAnim .3s linear 0s 1 normal;
}
@keyframes popupsAnim {
    0%{
        transform: scaleY(0);
    }
    100%{
        transform: scaleY(1);
    }
}
.products_actions_style_change_btn_imgg{
    margin-left: 45%;
    margin-bottom: 10px;
}
.products_actions_style_change_btn_data{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.users_switch_btn{
    width: 95px;
    height: 33px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    margin-left: 5px;
    color: #3E3636;
    font-size: 14px;
    text-transform: uppercase;
    transition: all linear .2s 0s;
}
.users_switch_btn:hover{
    background: #168C98;
    color: #fff;
}
.product_variant_show{
    width: 120px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #168C98;
    border-radius: 5px;
    padding: 0px 7px;
    text-transform: uppercase;
    font-size: 13.8px;
    cursor: pointer;
    transition: all .2s 0s linear;
}
.product_variant_show:hover{
    background: #168C98;
    color: #fff;
}