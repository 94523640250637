.newsletters_section {
  width: 100%;
  min-height: 80vh;
  background: lightgrey;
  padding: 30px;
  overflow: auto;
}
.delete_edit_hand,
.delete_edit_hand_1 {
  cursor: pointer;
  min-width: 200px;
  height: 35px;
  padding: 0px 7px;
  border: 1px solid #168c98;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 5px;
  transition: all linear 0.2s 0s;
}
.delete_edit_hand div {
  text-transform: uppercase;
  font-size: 15px;
}
.delete_edit_hand img {
  height: 20px;
}
.delete_edit_hand_1 {
  border: 1px solid #339af0;
  text-transform: uppercase;
  font-size: 15px;
}
.delete_edit_hand:hover,
.delete_edit_hand_1:hover {
  background: #168c98;
  color: #fff;
  border: 1px solid #168c98;
}

.delete_edit_hand_disabled div {
  text-transform: uppercase;
  font-size: 15px;
}
.delete_edit_hand_disabled img {
  height: 20px;
}

.delete_edit_hand_disabled {
  min-width: 200px;
  height: 35px;
  padding: 0px 7px;
  cursor: not-allowed;
  border: 1px solid #168c98;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 5px;
  transition: all linear 0.2s 0s;
}

.delete_edit_hand_disabled:hover {
  background: none;
  color: gray;
}

.common_style_image {
  width: 55px;
  height: 45px;
  border-radius: 7px;
}
.common_style_image img {
  width: 55px;
  height: 45px;
  border-radius: 7px;
  object-fit: fill;
  object-position: center;
}
.users_switch_btn {
  width: 95px;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-left: 5px;
  color: #3e3636;
  font-size: 14px;
  text-transform: uppercase;
  transition: all linear 0.2s 0s;
}
.users_switch_btn:hover {
  background: #168c98;
  color: #fff;
}
