.login_sign{
    width: 472px;
    height: 40px;
    margin: 0 auto;
    margin-top: 7px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.login_sign div{
    width: 33%;
    border: 1px solid #B3B3B3;
}
.login_sign p{
color:  #fff;
text-align: center;
font-family: Helvetica;
font-size: 16px;
font-style: normal;
font-weight: 400;
}