.settings_header_section{
    width: 100%;
    min-height: 40px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}
.settings_header_left{
    width: 200px;
    font-size: 18px;
    text-transform: uppercase;
    color: #3E3636;
    font-weight: 500;
    word-spacing: 5px;
}
.settings_header_right{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
.setting_header_button{
    min-width: 100px;
    height: 40px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    background: #fff;
    cursor: pointer;
    padding: 0px 7px;
    text-transform: uppercase;
    border: 1px solid #168C98;
    border-radius: 5px;
    word-spacing: 1px;
    margin-left: 2px;
    font-size: 15.5px;
}