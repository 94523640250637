.enquires_section{
    width: 100%;
    min-height: 80vh;
    background: lightgrey;
    padding: 30px;
}


.delete_edit_hand{
    cursor: pointer;
    width: 120px;
    height: 35px;
    padding: 0px 7px;
    border: 1px solid #339AF0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 5px;
    transition: all linear .2s 0s;
}
.delete_edit_hand div{
    text-transform: uppercase;
    font-size: 15px;
}
.delete_edit_hand img{
    height: 20px;
}
/* .delete_edit_hand:hover{
    background: #3E3636;
    color: #fff;
    border: none;
} */
.users_switch_btn{
    width: 95px;
    height: 33px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    margin-left: 5px;
    color: #3E3636;
    font-size: 14px;
    text-transform: uppercase;
    transition: all linear .2s 0s;
}
.users_switch_btn:hover{
    background: #3E3636;
    color: #fff;
}
.delete_edit_hand_1{
    cursor: pointer;
    width: 225px;
    height: 35px;
    padding: 0px 7px;
    border: 1px solid red;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 5px;
    transition: all linear .2s 0s;
}


.delete_edit_hand_1{
    border: 1px solid #168C98;
    text-transform: uppercase;
    font-size: 13px;
}

.delete_edit_hand_1:hover{
    background: #168C98;
    color: #fff;
    border: none;
}