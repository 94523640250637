.header_section {
  width: 100%;
  height: 75px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 10px #00000030;
  opacity: 1;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1000;
  padding: 0px 33px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header_left {
  width: 250px;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;
}
.header_right {
  width: 450px;
  height: 75px;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  justify-content: flex-end;
}
.header_search {
  width: 300px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header_search_text {
  width: 100px;
  height: 34.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #3e3636;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  font-size: 15px;
  font-family: Georgia, "Times New Roman", Times, serif;
  text-transform: uppercase;
  cursor: pointer;
}
.header_profile {
  width: 110px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #cbecff;
  border-radius: 6px;
  opacity: 1;
  font-size: 16px;
  font-family: Georgia, "Times New Roman", Times, serif;
  text-transform: capitalize;
  cursor: pointer;
  padding: 0px 5px;
}
.header_profile img {
  height: 17px;
  /* padding-right: 3px; */
}

.logoImageContainer {
  display: flex;
  justify-content: center;
  height: 2rem;
  width: 10.5rem;
}
