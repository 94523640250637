.otp_section_modl{
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, .9);
    position: fixed;
    top: 0px;
    left: 0px;
}
.otp_section_modl_container{
    width: 400px;
    height: 270px;
    background: #fff;
    position: relative;
    border-radius: 7px;
    padding: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.otp_section_modl_close{
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.otp_section_modl_close img{
    cursor: pointer;
}
.otp_section_modl_title{
    width: 100%;
    text-align: center;
    font-size: 17px;
    color: #000;
    font-weight: 500;
}
.otp_section_modl_email{
    width: 100%;
    text-align: center;
    font-size: 14px;
    color: grey;
    margin-top: 5px;
    margin-bottom: 15px;
}
.otp_section_modl_email span{
    color: #000;
}
.otp_section_modl_input{
    width: 100%;
    padding: 0px 20px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.otp_section_modl_input input{
    width: 40px;
    height: 40px;
    outline: none;
    border-radius: 3px;
    border: 1px solid grey;
    padding: 13px;
}
.otp_section_modl_resend{
    width: 100%;
    text-align: center;
    font-size: 13px;
    color: grey;
    margin: 18px 0px;
}
.otp_section_modl_resend span{
   color: #A08862;
   margin-left: 5px;
   cursor: pointer;
}
#buttonId_otp{
    width: 150px;
    margin: 0 auto;
    text-transform: capitalize;
    font-size: 16px;
    color: #fff;
    transition: all linear .2s 0s;
}