.statusPopup_section{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 1010;
    background:rgba(0, 0, 0, 0.3);
    transition: all linear 1s 0s;
    animation: popupsAnim .3s linear 0s 1 normal;
}
@keyframes popupsAnim {
    0%{
        transform: scaleY(0);
    }
    100%{
        transform: scaleY(1);
    }
}
.statusPopup_section_container{
    width: 400px;
    min-height:200px;
    background: #fff;
    border-radius: 7px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    padding: 20px;
}
.statusPopup_section_close{
    display: flex;
    justify-content: flex-end;
}
.statusPopup_section_close img{
    cursor: pointer;
}
.statusPopup_section_head{
    text-align: center;
    margin: 10px 0px;
    color:#168C98;
    font-size: 16px;
    text-transform: uppercase;
}
.appointments_form{
    width: 100%;
    min-height:180px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
}
.appointments_form_item{
    width: 47%;
    min-height: 50px;
}
.appointments_form_select{
    height: 50px;
    border: 1px solid #000;
    outline: none;
    border-radius: 5px;
}
.appointments_form_select:hover{
    border:none;
}
.appointments_form_submit_all{
    width: 100%;
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.appointments_form_submit{
    min-width: 120px;
    height: 40px;
    background: #168C98;
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 500;
    color: #fff;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
}
.appointments_form_submit:hover{
    border: none;
    background: #00A099;
}