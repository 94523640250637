.settings_section{
    width: 100%;
    min-height: 80vh;
    background: lightgrey;
    padding: 30px;
    overflow: auto;
}
.settings_panel_show{
    width: 100%;
    height: 75vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    text-transform: capitalize;
    color: #000;
}