*{
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
}
.layout_section{
    width: 100%;
    height: 100vh;
    display: flex;
}
.layout_child{
    width: 100%;
    height: 88vh;
    position: relative;
    top: 75px;
    right: 0px;
    padding-left: 250px;
    transition: all linear .2s 0s;
    background: lightgrey;
}
.layout_child_inactive{
    width: 100%;
    height: 88vh;
    position: relative;
    top: 75px;
    right: 0px;
    transition: all linear .2s 0s;
    padding-left: 75px;
    background: lightgrey;
}
