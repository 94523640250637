.appointments_section{
    width: 100%;
    min-height: 80vh;
    background: lightgrey;
    padding: 30px;
}


.delete_edit_hand{
    cursor: pointer;
    width: 120px;
    height: 35px;
    padding: 0px 7px;
    border: 1px solid #339AF0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 5px;
    transition: all linear .2s 0s;
}
.delete_edit_hand div{
    text-transform: uppercase;
    font-size: 15px;
}
.delete_edit_hand img{
    height: 20px;
}
.delete_edit_hand:hover{
    background: #3E3636;
    color: #fff;
    border: none;
}
.orders_section_product_pp{
    display: flex;
    align-items: center;
    justify-content: center;
}
.orders_section_product_pp img{
    cursor: pointer;
}