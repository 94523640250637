.usp_section{
    width: 100%;
    min-height: 80vh;
    background: lightgrey;
    overflow: auto;
}
.usp_container{
    width: 100%;
    min-height: 70vh;
    border-radius: 7px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    padding: 5px;
}
.usp_data_box{
    width: 310px;
    height: 400px;
    margin-bottom: 20px;
    padding: 10px 0px;
    background: #fff;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}
.usp_data_header{
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 4px;
    margin-bottom: 20px;
}
.usp_data_header_img{
    width: 25%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.usp_data_header_img img{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #168C98;
    object-fit: fill;
    padding: 8px;
}
.usp_data_header_text::-webkit-scrollbar {
    width: 2px;
  }
  .usp_data_header_text::-webkit-scrollbar-track {
    background: #fff; 
  }
  .usp_data_header_text::-webkit-scrollbar-thumb {
    background: #168C98; 
  }
  .usp_data_header_text::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
.usp_data_header_text{
    width: 75%;
    height: 50px;
    font-size: 14px;
    text-transform: capitalize;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow: auto;
}
.usp_data_main_image{
    width: 100%;
    height: 140px;
}
.usp_data_main_image img{
    width: 100%;
    height: 140px;
    object-fit: fill;
}
.usp_data_title::-webkit-scrollbar {
    width: 2px;
  }
  .usp_data_title::-webkit-scrollbar-track {
    background: #fff; 
  }
  .usp_data_title::-webkit-scrollbar-thumb {
    background: #168C98; 
  }
  .usp_data_title::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
.usp_data_title{
    width: 100%;
    height: 30px;
    margin: 7px 0px;
    color: #000;
    padding: 0px 10px;
    overflow: auto;
}
.usp_data_message::-webkit-scrollbar {
    width: 2px;
  }
  .usp_data_message::-webkit-scrollbar-track {
    background: #fff; 
  }
  .usp_data_message::-webkit-scrollbar-thumb {
    background: #168C98; 
  }
  .usp_data_message::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
.usp_data_message{
    width: 100%;
    height: 70px;
    margin: 7px 0px;
    color: #000;
    padding: 0px 10px;
    font-size: 14px;
    overflow: auto;
}
.usp_data_footer{
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 15px;
}
.delete_edit_hand,
.delete_edit_hand_1{
    cursor: pointer;
    width: 100px;
    height: 35px;
    padding: 0px 7px;
    border: 1px solid red;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 5px;
    transition: all linear .2s 0s;
}
.delete_edit_hand div{
    text-transform: uppercase;
    font-size: 15px;
}
.delete_edit_hand img{
    height: 20px;
}
.delete_edit_hand_1 img{
    height: 20px;
}
.delete_edit_hand_1{
    border: 1px solid #168C98;
    text-transform: uppercase;
    font-size: 15px;
}
.delete_edit_hand:hover,
.delete_edit_hand_1:hover{
    background: #168C98;
    color: #fff;
    border: none;
}





