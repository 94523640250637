.color_custom_loader {
    width:25px;
    height:25px;
    border-radius:50%;
    padding:1.5px;
    background: 
      radial-gradient(farthest-side,red 95%,#0000) 50% 0/6px 6px no-repeat,
      radial-gradient(farthest-side,#0000 calc(100% - 2.5px),red calc(100% - 2px)) content-box;
    animation:s6 1s infinite ;
  }
  @keyframes s6 {to{transform: rotate(1turn)}}