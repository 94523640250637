.PageHeader_section{
    width: 100%;
    height: 50px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    text-transform: capitalize;
    color: #2E384D;
    font-weight: 600;
    word-spacing: 5px;
    padding: 0px 3px;
}
.page_header_newbtn{
    display: flex;
}
.add_section_btn{
    cursor: pointer;
    min-width: 140px;
    height: 35px;
    padding: 0px 7px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 5px;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 400;
    word-spacing: 1px;
    transition: all linear .2s 0s;
    border: 1px solid #168C98;
    background: #168C98;
    color: #fff;
}
.add_section_btn img{
    margin-right: 5px;
}
.add_section_btn:hover{
    background: #fff;
    color: #3E3636;
}
.customer_add_new{
    cursor: pointer;
    min-width: 140px;
    height: 35px;
    border-radius: 5px;
    border: 1px solid #168C98;
    background: #168C98;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 10px;
    float:right;
    transition: all linear .2s 0s;
    margin-right: 10px;
}
.customer_add_new div{
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 400;
    word-spacing: -1px;
    margin-right: 5px;
    margin-left: 5px;
    word-spacing: 1px;
}
.customer_add_new img{
    height: 20px;
    margin-left: 10px;
}
.customer_add_new:hover{
    background: #fff;
    color: #3E3636;
}