.dashboard_section {
  width: 100%;
  min-height: 80vh;
  background: lightgrey;
  padding: 30px;
}
.dashboard_header {
  width: 100%;
  min-height: 250px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  padding: 20px;
}
.dashboard_header_hed {
  margin-bottom: 15px;
  color: #000000;
  font-weight: 600;
}
.dashboard_header_block {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;
}
.dashboard_chart {
  width: 100%;
  min-height: 300px;
  margin-top: 20px;
  display: flex;
  /* align-items: center; */
  flex-wrap: wrap;
  justify-content: space-between;
  border-radius: 7px;
}
.dashboard_piechart,
.dashboard_barchart {
  width: 49%;
  /* height: 500px; */
  padding: 20px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
}
.dashboard_chart_head {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-transform: capitalize;
  font-size: 18px;
  font-weight: 500;
}
.dashboard_barchart_head {
  width: 100%;
  margin-bottom: 100px;
  font-size: 16px;
  font-weight: 500;
}
